
  import {defineComponent, nextTick, reactive, toRefs} from 'vue';
  import type Node from 'element-plus/es/components/tree/src/model/node'
  import useNotes from "@/composables/useNotes";
  import {apiResponseErrorHandle} from "@/composables/errorHandler";
  import NoteViewHeader from "@/components/notes/NoteViewHeader.vue";
  import NoteView from "@/components/vditor-notes/NoteView.vue";
  import {MARKDOWN_ENGINE_VDITOR} from "@/constants/note";
  import useFolders from "@/composables/useFolders";
  import { ref } from 'vue';
  import { ElTree } from 'element-plus/es/components/tree';
  import {ElMessage, ElMessageBox} from "element-plus/es";
  import { DropType } from 'element-plus/es/components/tree/src/tree.type';
  import {DragEvents} from "element-plus/es/components/tree/src/model/useDragNode";
  import useMarkdownLinkCopy from "@/composables/useMarkdownLinkCopy";
  import MoveDialog from "@/components/folders/MoveDialog.vue";
  import EditTitleLine from "@/components/vditor-notes/EditTitleLine.vue";
  import useVditor from "@/composables/vditor";
  import Vditor from "vditor";
  import {useRoute, useRouter} from "vue-router";
  import { onMounted } from 'vue';
  import useFileTree from "@/composables/useFileTree";
  import {useStore} from "vuex";

  export default defineComponent({
    name: 'Index',
    components: {
      NoteViewHeader,
      NoteView,
      MoveDialog,
      EditTitleLine
    },
    setup() {
      const {getNote, createNote, updateNote, renameNote, moveNote, deleteNote, duplicateNote} = useNotes();
      const {getFoldersAndNotes, createFolder, updateFolder,
        moveFolder, deleteFolder, getRecursiveParents
      } = useFolders();
      const {listParentTreeByCurrentNoteId} = useFileTree();
      const {copyMarkdownLink} = useMarkdownLinkCopy();
      const router = useRouter();
      const route = useRoute();
      const store = useStore();

      const state = reactive({
        defaultRendering: 1,
        renderType: 1,
        renderingDialogVisible: false,
        anchor: '',
        shareDialogVisible: false,
        moveDialogVisible: false,
        overNodeKey: '',
        volatileOverNodeKey: null,
        movingNode: {},
        nodeOpMoreOpen: false,
        editing: false,
        isSaved: true
      });

      interface Tree {
        id: number
        key: string
        name: string
        leaf?: boolean
        class?: string
      }

      const props = {
        label: 'name',
        key: 'key',
        children: 'zones',
        isLeaf: 'leaf',
        class: 'custom-tree-node'
      }

      const treeRef = ref<InstanceType<typeof ElTree>>()
      const defaultExpandedKeys = ref<string[]>([])
      const vditor = ref<Vditor>()
      const editModeName = ref('')
      const id = ref<number | null>(null)
      const viewModeContent = ref('')
      const viewModeName = ref('')

      const noteIdQuery = route.query.noteId
      const noteIdFromUrl = Array.isArray(noteIdQuery) ? noteIdQuery[0] : noteIdQuery

      onMounted(() => {
        if (noteIdFromUrl) {
          get(Number.parseInt(noteIdFromUrl))
        }
      })

      const loadNode = (node: Node, resolve: (data: Tree[]) => void) => {
        if (noteIdFromUrl && !node.data.id) {
          listParentTreeByCurrentNoteId(noteIdFromUrl, 1).then(function (response) {
            resolve(response.data.tree)
            defaultExpandedKeys.value = response.data.parents.map((v: any) => "folder_" + v.id)
          }).catch(apiResponseErrorHandle('获取数据失败', () => resolve([])));
        } else {
          getFoldersAndNotes(node.data.id).then(function (response) {
            const data = makeTreeData(response.data);
            resolve(data)
          }).catch(apiResponseErrorHandle('获取数据失败', () => {
            node.loading = false
          }));
        }
      }

      const makeTreeData = (responseData: any): Tree[] => {
        const folders = responseData.folders.map((v: any) => {
          return {
            ...v,
            key: `folder_${v.id}`,
            leaf: false
          }
        });
        const leaves = responseData.notes.map((v: any) => {
          return {
            ...v,
            key: `note_${v.id}`,
            leaf: true
          }
        });
        return [].concat(folders).concat(leaves);
      }

      const overNode = (node: any) => {
        state.volatileOverNodeKey = node.key;
        state.overNodeKey = node.key;
      }

      let clearOverNodeTimeout: any
      const clearOverNode = () => {
        state.volatileOverNodeKey = null
        clearTimeout(clearOverNodeTimeout)
        clearOverNodeTimeout = setTimeout(() => {
          if (!state.nodeOpMoreOpen && !state.volatileOverNodeKey) {
            state.overNodeKey = '';
          }
        }, 500)
      }

      const refreshNode = (node: Node) => {
        getFoldersAndNotes(node.data.id).then(function (response) {
          defaultExpandedKeys.value = []
          const data = makeTreeData(response.data);
          treeRef.value?.updateKeyChildren(node.key, data);
        }).catch(apiResponseErrorHandle('获取数据失败'));
      }

      const newFolderOpen = (node: Node) => {
        ElMessageBox.prompt('请输入文件夹名称', '新建文件夹', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern:
            /[\w]*/,
          inputErrorMessage: '文件夹名称不可用',
        }).then(({value}) => {
          const parentId = node.data.id;
          createFolder(parentId, value).then(function (response) {
            const createdFolder = response.data.folder;
            const child = {
              ...createdFolder,
              key: `forder_${createdFolder.id}`,
              leaf: false
            }
            if (node.childNodes.length > 1) {
              treeRef.value?.insertBefore(child, node.childNodes[0]);
            } else {
              treeRef.value?.append(child, node);
            }
            ElMessage({
              showClose: true,
              message: '创建成功',
              type: 'success',
            });
          }).catch(apiResponseErrorHandle('创建失败'));
        }).catch(() => {
          return;
        })
      }

      const newNote = (node: Node) => {
        const parentId = node.data.id;
        createNote(MARKDOWN_ENGINE_VDITOR, parentId).then(function (response) {
          const createdNote = response.data.note;
          const child = {
            ...createdNote,
            key: `note_${createdNote.id}`,
            leaf: true
          }
          if (node.childNodes.length > 1) {
            treeRef.value?.insertBefore(child, node.childNodes[0]);
          } else {
            treeRef.value?.append(child, node);
          }
          ElMessage({
            showClose: true,
            message: '创建成功',
            type: 'success',
          });
        }).catch(apiResponseErrorHandle('创建失败'));
      }

      const deleteNoteOpen = (node: Node) => {
        const noteId = node.data.id;
        ElMessageBox.confirm(
          `是否删除 "${node.data.name}" ？`,
          '提示',
          {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'warning',
          }).then(() => {
            deleteNote(noteId).then(function (response) {
              treeRef.value?.remove(node)
              ElMessage({
                showClose: true,
                message: '删除成功',
                type: 'success',
              });
            }).catch(apiResponseErrorHandle('删除失败'));
        }).catch(() => {
          return;
        })
      }

      const deleteFolderOpen = (node: Node) => {
        const folderId = node.data.id;
        ElMessageBox.confirm(
          `是否删除 "${node.data.name}" ？`,
          '提示',
          {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'warning',
          }).then(() => {
          deleteFolder(folderId).then(function (response) {
            treeRef.value?.remove(node)
            ElMessage({
              showClose: true,
              message: '删除成功',
              type: 'success',
            });
          }).catch(apiResponseErrorHandle('删除失败'));
        }).catch(() => {
          return;
        })
      }

      const canLeave = async () => {
        if (!state.isSaved) {
          const answer = await ElMessageBox.confirm(
            '内容还没保存！确定离开吗？',
            '内容还没保存！',
            {
              confirmButtonText: '确定离开',
              cancelButtonText: '暂不离开',
              type: 'warning',
            }
          )
          if (!answer) {
            return false
          } else {
            state.isSaved = true
            return true
          }
        } else {
          return true
        }
      }

      const nodeClick = async (data: any, node: Node, event: Event) => {
        if (data.fileType === 2) {
          if (!await canLeave()) return
          await router.push({ name: 'browse', query: { noteId: data.id } })
          state.editing = false
          clearViewModeContent()
          get(data.id)
        }
      }

      const clearViewModeContent = () => {
        viewModeName.value = '';
        viewModeContent.value = '';
        state.defaultRendering = 1;
        state.renderType = 1;
      }

      const clearEditModeContent = () => {
        editModeName.value = '';
      }

      const get = (noteId: number | null) => {
        noteId && getNote(noteId).then(function (response) {
          id.value = noteId;
          viewModeName.value = response.data.note.name;
          viewModeContent.value = response.data.note.content;
          state.defaultRendering = response.data.note.defaultRendering;
          state.renderType = state.defaultRendering;
        }).catch(apiResponseErrorHandle('获取数据失败'))
      }

      const renderTypeChanged = (type: number) => {
        state.renderType = type
      }

      const defaultRenderingChanged = (renderingValue: number) => {
        state.defaultRendering = renderingValue;
      }

      const allowDrop = (draggingNode: Node, dropNode: Node, type: DropType) => {
        if (type == 'inner') {
          return dropNode.data.fileType === 1
        } else {
          return true
        }
      }

      const allowDrag = (draggingNode: Node) => {
        return true
      }

      const handleDrop = (
        draggingNode: Node,
        dropNode: Node,
        dropType: DropType,
        ev: DragEvents
      ) => {
        let targetFolderId;
        if (dropType !== 'inner') {
          targetFolderId = dropNode.parent.data.id;
        } else {
          targetFolderId = dropNode.data.id;
        }
        if (draggingNode.data.fileType == 1) {
          moveFolder(draggingNode.data.id, targetFolderId).then(function (response) {
            ElMessage({
              showClose: true,
              message: '移动成功',
              type: 'success',
            });
          }).catch(apiResponseErrorHandle('移动失败'));
        } else if (draggingNode.data.fileType == 2) {
          moveNote(draggingNode.data.id, targetFolderId).then(function (response) {
            ElMessage({
              showClose: true,
              message: '移动成功',
              type: 'success',
            });
          }).catch(apiResponseErrorHandle('移动失败'));
        }
      }

      const duplicate = (node: Node) => {
        if (node.data.fileType !== 2) {
          return
        }
        duplicateNote(node.data.id).then(function(response) {
          const duplicateNote = response.data.note;
          const child = {
            ...duplicateNote,
            key: `note_${duplicateNote.id}`,
            leaf: true
          }
          treeRef.value?.insertBefore(child, node);
          ElMessage({
            showClose: true,
            message: '复制成功',
            type: 'success',
          });
        }).catch(apiResponseErrorHandle('复制失败'))
      }

      const copyLink = (node: Node) => {
        if (copyMarkdownLink(node.data)) {
          ElMessage({
            showClose: true,
            message: '复制成功',
            type: 'success'
          });
        }
      }

      const move = (node: Node) => {
        state.movingNode = node;
        state.moveDialogVisible = true;
      }

      const onCloseMoveDialog = () => {
        state.moveDialogVisible = false
      }

      const onMoved = () => {
        state.moveDialogVisible = false;
        state.movingNode && treeRef.value?.remove(state.movingNode)
      }

      const renameFolderOpen = (node: Node) => {
        ElMessageBox.prompt('请输入文件夹名称', '重命名文件夹', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: node.data.name,
          inputPattern:
            /[\w]*/,
          inputErrorMessage: '文件夹名称不可用',
        }).then(({value}) => {
          updateFolder(node.data.id, value).then(function (response) {
            node.data.name = value
            ElMessage({
              showClose: true,
              message: '修改成功',
              type: 'success',
            });
          }).catch(apiResponseErrorHandle('修改失败'));
        }).catch(() => {
          return;
        })
      }

      const renameNoteOpen = (node: Node) => {
        ElMessageBox.prompt('笔记标题', '重命名', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: node.data.name,
          inputPattern:
            /[\w]*/,
          inputErrorMessage: '标题不可用',
        }).then(({value}) => {
          renameNote(node.data.id, value).then(function (response) {
            node.data.name = value
            ElMessage({
              showClose: true,
              message: '修改成功',
              type: 'success',
            });
          }).catch(apiResponseErrorHandle('修改失败'));
        }).catch(() => {
          return;
        })
      }

      const nodeOpMoreVisibleChange = (visible: boolean) => {
        state.nodeOpMoreOpen = visible
      }

      const onEditClick = () => {
        vditor.value?.setValue('')
        state.editing = true
        setTimeout(() => {
          if (id.value) {
            vditor.value = useVditor(id.value, onContentChange, store)
            clearEditModeContent()
            getEditContent(id.value)
          }
        }, 50)
      }

      const onViewClick = async () => {
        if (!await canLeave()) return
        state.editing = false
        get(id.value)
      }

      const onTitleChange = (value: string) => {
        editModeName.value = value
        state.isSaved = false
      }

      const onContentChange = (value: string) => {
        state.isSaved = false
      }

      const onSubmitForm = () => {
        const updateId = id.value
        const updateName = editModeName.value
        updateId && vditor.value && updateNote(updateId, updateName, vditor.value.getValue())
          .then(function (response) {
          if (!response) {
            return
          }
          const node = treeRef.value?.getNode('note_' + updateId)
          if (node) {
            node.data.name = updateName
          }
          ElMessage({
            showClose: true,
            message: '保存成功',
            type: 'success',
          });
          state.isSaved = true
        }).catch(function (error) {
          const data = error.response.data;
          if (data != null) {
            ElMessage({
              showClose: true,
              message: error.response.data.error,
              type: 'error',
            });
          } else {
            ElMessage({
              showClose: true,
              message: '保存失败',
              type: 'error',
            });
          }
        });
      }

      const getEditContent = (noteId: number) => {
        getNote(noteId).then(function(response) {
          editModeName.value = response.data.note.name;
          vditor.value?.setValue(response.data.note.content);
        }).catch(function (error) {
          const data = !error.response ? null : error.response.data;
          if (data != null) {
            ElMessage({
              showClose: true,
              message: error.response.data.error,
              type: 'error',
            });
          } else {
            ElMessage({
              showClose: true,
              message: '获取数据失败',
              type: 'error',
            });
          }
        })
      }

      return {
        ...toRefs(state),
        renderTypeChanged,
        defaultRenderingChanged,
        loadNode,
        overNode,
        clearOverNode,
        nodeClick,
        refreshNode,
        newFolderOpen,
        newNote,
        deleteFolderOpen,
        deleteNoteOpen,
        allowDrop,
        allowDrag,
        handleDrop,
        duplicate,
        copyLink,
        move,
        onCloseMoveDialog,
        onMoved,
        renameFolderOpen,
        renameNoteOpen,
        nodeOpMoreVisibleChange,
        onEditClick,
        onViewClick,
        onTitleChange,
        onContentChange,
        onSubmitForm,
        engineType: MARKDOWN_ENGINE_VDITOR,
        props,
        treeRef,
        defaultExpandedKeys,
        editModeName,
        id,
        viewModeContent,
        viewModeName
      }
    },

    async beforeRouteLeave(to, from) {
      if (!this.isSaved) {
        const answer = await ElMessageBox.confirm(
          '内容还没保存！确定离开吗？',
          '内容还没保存！',
          {
            confirmButtonText: '确定离开',
            cancelButtonText: '暂不离开',
            type: 'warning',
          }
        );
        if (!answer) {
          return false
        } else {
          this.isSaved = true
          return true
        }
      }
    }
  });
