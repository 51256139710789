import { useStore } from 'vuex'
import {getTreeOfRootReq, listParentTreeByCurrentNoteIdReq} from "@/api/fileTree";

export default function useFileTree() {
  const store = useStore();

  const listParentTreeByCurrentNoteId = async (currentNoteId: number | string | null = null,
                                               depth: number | null = 1000) => {
    const teamId = store.state.currentTeam?.id
    return await listParentTreeByCurrentNoteIdReq(currentNoteId, teamId, depth);
  }

  const getTreeOfRoot = async () => {
    const teamId = store.state.currentTeam?.id
    return await getTreeOfRootReq(teamId);
  }

  return {
    listParentTreeByCurrentNoteId,
    getTreeOfRoot
  }

}