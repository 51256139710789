import axios from "axios";
import {LIST_PARENT_TREE_BY_CURRENT_NOTE_ID, TREE_OF_ROOT} from "@/constants/api";

export async function listParentTreeByCurrentNoteIdReq(currentNoteId: number | string | null = null,
                                                       teamId: number | null = null,
                                                       depth: number | null = 1000) {
  return await axios.get(LIST_PARENT_TREE_BY_CURRENT_NOTE_ID, {
    params: {
      currentNoteId: currentNoteId,
      teamId: teamId,
      depth: depth
    }
  });
}

export async function getTreeOfRootReq(teamId: number | null = null) {
  return await axios.get(TREE_OF_ROOT, {
    params: {
      teamId: teamId
    }
  });
}